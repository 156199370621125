import {
  styled,
  themeable,
  ButtonFrame,
  ButtonText,
  ButtonProps as TamaguiButtonProps,
  useButton,
  TamaguiElement,
  GetProps,
  isWeb,
  Spinner,
} from '@mythical/ui'
import config from 'app/config'
import { isIOS } from 'app/utils/constants'
import React, { forwardRef } from 'react'
import { Linking } from 'react-native'
import { InAppBrowser } from 'react-native-inappbrowser-reborn'
import { useLink } from 'solito/link'

const CustomButtonFrame = styled(ButtonFrame, {
  borderColor: '$borderColor',
  borderRadius: '$2',
  hoverStyle: {
    borderColor: '$borderColorHover',
  },
  focusStyle: {
    borderColor: '$borderColorFocus',
  },
  pressStyle: {
    borderColor: '$borderColorPress',
  },
  variants: {
    // Overrides theme and makes the whole button transparent
    plain: {
      true: {
        bg: '$transparent',
        borderColor: '$transparent',
        hoverStyle: {
          bg: '$transparent',
          borderColor: '$transparent',
          opacity: 0.9,
        },
        pressStyle: {
          bg: '$transparent',
          borderColor: '$transparent',
          opacity: 0.9,
        },
        focusStyle: {
          bg: '$transparent',
          borderColor: '$transparent',
          opacity: 0.9,
        },
      },
    },
    // Makes background transparent, but keeps border from the theme for outline buttons
    transparent: {
      true: {
        bg: '$transparent',
        hoverStyle: {
          bg: '$transparent',
          opacity: 0.9,
        },
        pressStyle: {
          bg: '$transparent',
          opacity: 0.8,
        },
        focusStyle: {
          bg: '$transparent',
          opacity: 0.85,
        },
      },
    },
    disabled: {
      true: {
        o: 0.5,
        cursor: 'default',
        hoverStyle: {
          bg: '$background',
          borderColor: '$borderColor',
        },
        pressStyle: {
          bg: '$background',
          borderColor: '$borderColor',
        },
        focusStyle: {
          bg: '$background',
          borderColor: '$borderColor',
        },
      },
    },
  } as const,
})

const CustomButtonText = styled(ButtonText, {
  letterSpacing: 1,
  textTransform: 'uppercase',
  fontSize: '$3',
  variants: {
    uppercase: {
      true: {
        textTransform: 'uppercase',
      },
      false: {
        textTransform: 'none',
      },
    },
    disabled: {
      true: {
        cursor: 'default',
      },
    },
  } as const,
  defaultVariants: {
    uppercase: true,
  },
})

type CustomButtonProps = GetProps<typeof CustomButtonFrame>
type CustomButtonTextProps = GetProps<typeof CustomButtonText>

export type ButtonProps = TamaguiButtonProps &
  CustomButtonProps &
  CustomButtonTextProps & {
    loading?: boolean
  }

export const Button = themeable(
  forwardRef<TamaguiElement, ButtonProps>((propsIn, ref) => {
    const {
      uppercase = true,
      disabled,
      color,
      loading,
      lineHeight,
      textAlign,
      allowFontScaling,
      ...rest
    } = propsIn

    const textProps = {
      uppercase,
      color,
      disabled,
      lineHeight,
      textAlign,
      allowFontScaling
    }

    const buttonProps = {
      ...rest,
      disabled,
      ...(loading && {
        icon: <Spinner />,
        disabled: true,
      }),
    }

    const { props } = useButton(buttonProps, {
      Text: (props) => <CustomButtonText {...props} {...textProps} />,
    })
    return <CustomButtonFrame {...props} ref={ref} />
  })
)

export type ButtonLinkProps = {
  href: string
  onPressCustom?: () => void
  target?: string
  auth?: boolean
} & ButtonProps

export const ButtonLink = React.forwardRef<TamaguiElement, ButtonLinkProps>(
  ({ href, onPressCustom, target, auth, ...props }, ref) => {
    const linkProps = useLink({
      href,
    })
    const onPress = async (e) => {
      e.preventDefault()
      onPressCustom && onPressCustom()

      if (!href.startsWith('http') && !href.startsWith('/api')) {
        return linkProps.onPress()
      }

      if (isWeb) {
        return window.open(href, target)
      }

      if (auth && isIOS && (await InAppBrowser.isAvailable())) {
        InAppBrowser.openAuth(href, config?.redirectUrl || '', {
          showTitle: false,
          enableUrlBarHiding: true,
          enableDefaultShare: false,
          ephemeralWebSession: false,
        }).then((result) => {
          if (result.type === 'success' && result.url) {
            Linking.openURL(result.url)
          }
        })
      } else {
        Linking.openURL(href)
      }
    }

    return <Button {...props} {...linkProps} onPress={onPress} ref={ref} />
  }
)
